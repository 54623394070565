var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "wrapper__card" }, [
        _c("div", { staticClass: "wrapper__card__header" }, [
          _c("strong", [_vm._v("Barra de filtros")]),
        ]),
        _c("div", { staticClass: "wrapper__card__body" }, [
          _c("div"),
          _c("div"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }